@import "../../../../resources/styles/colors";

.taskDetailsCell {
    vertical-align: "middle";
}

.stats {
    padding-left: 1rem;
}

.states {
    width: 16rem;
}

.checkboxFiltersContainer {
    margin-bottom: 1rem;
}

.taskRow {
    &:hover {
        background-color: @hover;
    }
}

.datepickerWrap {
    padding-bottom:1rem;
}