@import "../../resources/styles/media";
@import "../../resources/styles/colors";

.bodyWithSides {
    display: flex;
    //flex: 1 1 auto; //TODO: markse review. This is causing issues with sidebar layouts stretching outside their parent bounds.
    padding: 0;

    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}

@sideBarBorder: 0.0625rem solid @dividerLight;
.sideBarRight {
    @media (min-width: @screen-sm) {
        border-left: @sideBarBorder;
    }
}

.sideBarLeft {
    @media (min-width: @screen-sm) {
        border-right: 0.0625rem solid @dividerLight;
    }
}

.sidebarContainer {
    flex-basis: 17.6rem;
    padding-bottom: 1rem;
}

.sideBar {
    font-size: 0.875rem;
    color: @primaryText;

    @media (max-width: @screen-sm) {
        border-top: @sideBarBorder;
    }
}

.sideBarPadding {
    padding: 0 1rem;
}

.main {
    flex-grow: 1;
    flex-basis: 1rem;
    min-width: 0;

    // fixes ie11
    @media (max-width: @screen-sm) {
        flex-basis: auto;
    }
}

.overflowXHidden {
    overflow-x: hidden;
}

.mainContent {
    padding-top: 0;
    height: 100%;
    overflow-x: auto; // Needed when the content is a table that pushes the boundary of the parent.
}
