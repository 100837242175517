@drawer-width: 975px;

// the double class selector is a hack to increase 
// the specificity of these styles, to override 
// material-ui defaults without resorting to !important
.container.container {
  width: 90%;
  max-width: @drawer-width;
  overflow-y: visible;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
}

.title {
  margin: 0;
}

.content {
  overflow: auto;
}
