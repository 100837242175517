@import "../../resources/styles/colors.less";
@import "../../resources/styles/variables.less";

.container {
    height: 100%;
    display: flex;
    flex-direction: column;
}

.codeEditorContainer {
    position: relative;
    min-height: 15rem;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    border-bottom-left-radius: @radiusMd;
    border-bottom-right-radius: @radiusMd;
    transition: 0.3s;
    border-bottom: 1px solid #2E475D;
    border-left: 1px solid #2E475D;
    border-right: 1px solid #2E475D;
    flex-grow: 1;

    &.no-toolbar {
        border-radius: @radiusMd;
    }
}

.focused {
    min-height: 26rem;
}

.containerInADialog {
    &.codeEditorContainer {
        border-radius: 0;
    }

    &.toolbar {
        border-radius: 0;
    }
}

.codeEditorContainerFullScreen {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: auto;
    height: auto;
    z-index: 1300;
    background: @paper0;
}

.exitFullScreen {
    position: fixed;
    z-index: 1301;
    top: 0;
    right: 0;
    display: flex;
    justify-content: flex-end;
    background-color: transparent;
}

.toolbar {
    border-top: 1px solid #2E475D;
    border-left: 1px solid #2E475D;
    border-right: 1px solid #2E475D;

    border-top-left-radius: @radiusMd;
    border-top-right-radius: @radiusMd;
    z-index: 9; // Needs to be less then the sticky components
    background-color: @codeEditorToolbarBackground;
    display: flex;
    justify-content: space-between;
    color: #FFFFFF;
    max-height: 50px;
    gap: 12px;
    padding: 8px 12px;
    box-shadow: @shadowSmall;
}

.toolbarButtonsContainer {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    flex-direction: row;
    align-items: center;
}

.toolbarButtonsContainer:only-child {
    margin-left: auto;
}

@container (max-width: 664px) {
    .toolbar {
        flex-direction: column;
        max-height: 95px;
        gap: 0.5rem;
        align-items: flex-start;
    }
}

@container (max-width: 474px) {
    .toolbar {
        max-height: 157px;
    }

    .toolbarButtonsContainer {
        flex-direction: column;
        gap: 4px;
        align-items: flex-start;
    }

    .toolbarButtonsContainer:only-child {
        margin-left: unset;
    }
}

.toolbarButton {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #FFFFFF;
    font-size: 14px;
    padding-left: 8px;
    padding-right: 8px;
    cursor: pointer;
    border-radius: 4px;
    gap: 4px;
    height: 30px;
    background-color: @codeEditorToolbarBackground;
    user-select: none;

    &:hover {
        background-color: @codeEditorToolbarButtonHover;
        color: #EBF6FF;

        .toolbarIconButton { color: #EBF6FF !important; }
    }
}

.toolbar:last-child {
    margin-right: 0;
}

.toolbarSelect {
    margin: 0;
    height: 34px;

    &> div {
        color: #FFFFFF;
        font-size: 14px;
        border-radius: 4px;
        margin: 0px;
        cursor: pointer;
    }
}

.toolbarIconButton {
    color: #FFFFFF !important;
    opacity: 1;
    font-size: 14px !important;
    font-weight: normal !important;

    padding: 0 !important;
    width: auto !important;
    height: 2rem !important;
    text-transform: capitalize !important;

    :global {
        .MuiButton-iconSizeMedium > :first-child {
            font-size: 1rem;
            width: 24px;
            text-align: center;
            justify-items: center;
            padding-left: 4px;
        }
    }

    span {
        padding: 0 !important;
    }

    svg {
        fill: #FFFFFF !important;
        color: #FFFFFF !important;
        height: 24px;
        width: 24px;
    }
    .cancelSmall{
        height: 20/16rem !important;
        width: 20/16rem !important;
    }

    &:hover {
        background-color: transparent !important;
    }
}

.hintHighlight {
    color: @hintHighlight;
    font-weight: bold;
}

.codeMirror {
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        font-size: 1rem;
        position: absolute;
    :global {
        .CodeMirror-fullscreen {
            top: 0;
            height: 100% !important;
        }

        .CodeMirror {
            height: inherit;
            font-family: Consolas, Menlo, Monaco, "Courier New", monospace;
            font-size: 1rem;
            background: @codeEditorBackground !important;
            color: @codeEditorDefaultTextColor;
            padding: 0.5rem 0;
        }

        .CodeMirror pre.CodeMirror-line {
            color: @codeEditorDefaultTextColor;
        }

        .readonly .CodeMirror-cursors {
            visibility: hidden !important;
        }

        .cm-s-default.CodeMirror {
            background-color: @codeEditorBackground;
            color: @codeEditorDefaultTextColor;
        }

        .cm-s-default .CodeMirror-gutters {
            background: @codeEditorBackground;
            color: #416F91;
            border: none;
        }

        .cm-s-default .CodeMirror-guttermarker,
        .cm-s-default .CodeMirror-guttermarker-subtle,
        .cm-s-default .CodeMirror-linenumber {
            color: #416F91;
        }

        .cm-s-default .CodeMirror-cursor {
            border-left-width: 1px;
            border-left-style: solid;
            border-left-color: var(--sunshineText);
        }

        .cm-s-default div.CodeMirror-selected {
            background: @codeEditorTextHighlight;
        }

        .cm-s-default.CodeMirror-focused div.CodeMirror-selected {
            background: @codeEditorTextHighlight;
        }

        .cm-s-default .CodeMirror-line::selection,
        .cm-s-default .CodeMirror-line > span::selection,
        .cm-s-default .CodeMirror-line > span > span::selection {
            background: @codeEditorTextHighlight;
        }

        .cm-s-default .CodeMirror-line::-moz-selection,
        .cm-s-default .CodeMirror-line > span::-moz-selection,
        .cm-s-default .CodeMirror-line > span > span::-moz-selection {
            background: @codeEditorTextHighlight;
        }

        .cm-s-default .CodeMirror-activeline-background {
            background: var(--primaryBackground);
        }

        .cm-s-default .cm-keyword {
            color: @codeEditorTextBright;
        }

        .cm-s-default .cm-operator {
            color: @codeEditorOperator;
        }

        .cm-s-default .cm-variable-2 {
            color: @codeEditorVariable2;
        }

        .cm-s-default .cm-variable-3,
        .cm-s-default .cm-type {
            color: @codeEditorType;
        }

        .cm-s-default .cm-builtin {
            color: @codeEditorBuiltIn;
        }

        .cm-s-default .cm-atom {
            color: @codeEditorAtom;
        }

        .cm-s-default .cm-number {
            color: @codeEditorNumber;
        }

        .cm-s-default .cm-def {
            color: @codeEditorDef;
        }

        .cm-s-default .cm-string {
            color: @codeEditorString;
        }

        .cm-s-default .cm-string-2 {
            color: @codeEditorType;
        }

        .cm-s-default .cm-comment {
            color: @codeEditorDefaultTextColor;
        }

        .cm-s-default .cm-variable {
            color: @codeEditorVariable;
        }

        .cm-s-default .cm-tag {
            color: @codeEditorVariable;
        }

        .cm-s-default .cm-meta {
            color: @codeEditorAtom;
        }

        .cm-s-default .cm-attribute {
            color: @codeEditorTextBright;
        }

        .cm-s-default .cm-property {
            color: @codeEditorTextBright;
        }

        .cm-s-default .cm-qualifier {
            color: @codeEditorQualifier;
        }

        .cm-s-default .cm-error {
            color: var(--blackConstant);
            background-color: var(--dangerConstant);
        }

        .cm-s-default .CodeMirror-matchingbracket {
            text-decoration: underline;
            color: var(--whiteConstant) !important;
        }

        .CodeMirror-foldmarker {
            color: @primaryText;
            text-shadow: @secondaryText 1px 1px 2px, @secondaryText -1px -1px 2px, @secondaryText 1px -1px 2px, @secondaryText -1px 1px 2px;
            font-family: arial;
            line-height: 0.3;
            cursor: pointer;
        }

        .CodeMirror-foldgutter {
            width: 0.7em;
        }

        .CodeMirror-foldgutter-open,
        .CodeMirror-foldgutter-folded {
            cursor: pointer;
        }

        .CodeMirror-foldgutter-open:after {
            content: "\25BE";
        }

        .CodeMirror-foldgutter-folded:after {
            content: "\25B8";
        }

        .CodeMirror-gutters {
            background: @codeEditorBackground;
            color: @codeEditorGutterTextColor;
            border: none;
        }

        .CodeMirror-empty {
            color: #416F91 !important;
        }

        .CodeMirror-linenumber {
            color: @codeEditorGutterTextColor;
        }
    }
}

:global {
    .CodeMirror-hints {
        z-index: 99999 !important;
        background: @paper2 !important;
        font-family: Consolas, Menlo, Monaco, "Courier New", monospace !important;
    }

    .CodeMirror-hint {
        color: @placeholder !important;
    }

    li.CodeMirror-hint-active {
        background: @hintActive !important;
        color: @placeholderActive !important;
    }
}

.titleSection {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    font-size: 18px;
    color: #FFFFFF;
}

.autocompleteNoteContainer {
    margin-top: 0px;
}

.autocompleteNote {
    font-size: 14px;
    margin-bottom: 0;
}

.customDialogActions {
    background-color: @codeEditorBackground;
    border-top: 1px solid @codeEditorGutterTextColor;
    align-items: center;

    .autocompleteNote {
        color: #FFFFFF;
    }
}

.fuzzySearchTooltip {
    line-height: 24px;
}

.closeButton {
    background-color: #07121A !important;
}