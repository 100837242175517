@import "../../resources/styles/media";
@import "../../resources/styles/colors";
@import "../../resources/styles/variables";

.panel {
    position: relative;
    margin: 1rem;
    .header {
        margin: 0;
        padding: 0 20/16rem;
        text-align: center;
        img {
            max-width: 90%;
            height: auto;
        }
    }
    .body {
        padding: 20/16rem 10/16rem;
        font-size: 14/16rem;
        line-height: 19/16rem;
        ol,
        ul {
            padding-left: 40/16rem;
        }
        ul {
            list-style: square;
        }
        ol {
            list-style: decimal;
        }
    }
}

.onboardingOuterContainer {
    margin: 1rem auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 75%;
}

.onboardingBlurred {
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    /* filter doesn't work for IE11, known issue, we fallback to opacity only in this case */
    opacity: 0.8;
    transition: opacity 300ms ease-out;
    -moz-transition: opacity 300ms ease-out;
    -webkit-transition: opacity 300ms ease-out;
}

.onboardingBlurred:hover {
    -webkit-filter: unset;
    filter: unset;
    opacity: 1.0;
    transition: opacity 300ms ease-in;
    -moz-transition: opacity 300ms ease-in;
    -webkit-transition: opacity 300ms ease-in;
}

.onboardingContainer {
    display:flex;
    flex-direction:column;
    align-items: stretch; // Needed for IE11 ><
    width: 100%; // Needed for IE11 ><
    color: @ternaryText;
    font-size: 0.875rem;
}

.header {
    text-align: center;
    width: 100%; // Needed for IE11 ><
    color: @primaryText;
    font-weight: @semibold;
}

.intro {
    color: @ternaryText;
    display: flex;
    justify-content: center;
    margin-bottom:1rem;
    text-align: center;
}

.learnMore {
    color: @ternaryText;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
    a {
        margin: 0 1.5rem 1rem;
        @media (max-width: @screen-sm) {
            margin: 0 0 .5rem;
        }
    }
}

.onboardingImage {
    text-align: center;
    width: 100%; // Needed for IE11 ><
    margin: 1.5rem 0;
}

.onboardingCreateFirst {
    text-align: center;
    font-size:13/16rem;
    color: @ternaryText;
    margin-top:0.5rem;
}

.panels {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%; // To help IE11 scale images to the container (super important at smaller resolutions in IE). See tenants onboarding for an example of this nightmare.
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.panels>* {
    flex-grow: 1;
    max-width: 600/16rem;
    //width: 30%;
    box-sizing: border-box;
}

@media (max-width: @screen-sm) {
    .panels>* {
        width: 100%;
    }
    .onboardingOuterContainer {
        padding: 0.25rem 0.5rem;
        width:100%;
    }
}