@import "../../../../../resources/styles/colors";

.scopeRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 0 20/16rem;

    h4 {
        color: @ternaryText;
    }
}

.scopeRow > * {
    max-width: 250/16rem;
    min-width: 150/16rem;
    flex: 1 1 150/16rem;
}

.showAll {
    margin-top: 0.5rem;
}

.summary {
    padding-left: 1rem;
    padding-bottom: 1rem;
    font-size: 0.875rem;
    color: @ternaryText;
}

.filterTextBox {
    padding-left: 1rem;
}

.sectionControlContainer {
    display: flex;
    flex-direction: row;
    flex: auto;
    align-items: center;
    justify-content: space-between;
    padding-left: 1rem;
}