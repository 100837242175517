@import "../../resources/styles/colors";

.markdown {
    line-height: normal;
    .list() {
        padding-left: 2.5rem;
    }

    ul {
        list-style-type: disc;
        .list();
    }

    ol {
        list-style-type: decimal;
        .list();
    }

    blockquote {
        border-left: 0.25rem solid @dividerLight;
        padding-left: 1rem;
    }
}

.secondaryText {
    color: @secondaryText;
    p {
        color: @secondaryText;
    }
}