@import "../../resources/styles/media";
@import "../../resources/styles/colors";

.paperContent {
    box-shadow: @shadowExtraSmall;
    border-radius: 0.25rem;
    //overflow: hidden; // Do not hide overflow to this, or various variable popovers can get cut off (I.e. variables screen).
    background-color: @paper1;
    width: 100%;
    max-width: 1280px;
    margin: 0 0 1rem 0;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
}

.paperContentFlat {
    box-shadow: 0;
    background-color: transparent;
    width: 100%;
    max-width: 1280px;
    margin: 0;
    position: relative;
    display: flex;
    align-items: stretch;
    flex-direction: column;
    @media (max-width: @screen-sm) {
        margin: 0.8rem;
    }
}

.paperFullWidth{
    max-width: none;
}

.container {
    display: flex;
    width: 100%;
    justify-content: center;
}

.headerContainer {
    background: @paper1;
    border-radius: 0.25rem;
}

.busyFromPromise {
    // position: absolute; //mark.siedle: Having this positioned absolute made the busy indicators dissappear from the floating PaperLayout header.
    width: 100%;
}

.title {
    word-wrap: break-word;
    background-color: @paper1;
}

.stickied {
    box-shadow: @shadowSmall;
}