.overviewContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.overviewRow {
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.selectFilter {
    min-width: 12rem;
}

.loadSampleButton {
    margin-left: 1rem;
}