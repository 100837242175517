@import "../../resources/styles/colors";
@import "../../resources/styles/media.less";

.actionList {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    flex-wrap: wrap; // Need this to wrap for mobile.
    gap: 0.5rem;
}

.alignStart {
    justify-content: flex-start;
}

.alignEnd {
    justify-content: flex-end;
}

