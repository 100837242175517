@import "../../resources/styles/colors";
@import "../../resources/styles/media";

.container {
    display: flex;
    padding: 1rem 1rem;
    align-items: center;
    flex-wrap: wrap; // Need this to wrap for mobile.
    justify-content: space-between;
    min-height: 4.25rem;
}

.titleContainer {
    display: flex;
    flex-direction: column; // Needed so we wrap release links above title.
}

.header {
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;
    color: @primaryText;

    .helpIconContainer {
      margin-left: 0.25rem;
      transform: scale(0.8);
    }
    .helpIconContainerClosed {
      opacity: 0.4; // Helps this fade into the background when we don't want users focusing on it.
    }
}

.title {
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.25rem;
  line-height: 1.75rem; // 28px - this matches our SectionTitle's RefreshIndicator's size.
  margin: 0.25rem 0;
}

.logo {
  margin-right: .5rem;
}

.refreshContainer {
    display:flex;
    width: fit-content;
    height: 1.75rem; // Needed, or it can throw out the height of our SectionTitle.
    margin-left: 0.5rem;
    align-items: center;
}

.refreshIndicator {
  width: 2.5rem;
}

.breadcrumbContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sidebarText {
  @media (max-width: @drawerBreakpointWidth) {
    display: none;
  }
}

.sidebarTextToBackground {
  opacity: 0.5;
}

.pageTitleLink {
  color: @mainText;
  text-decoration: none;
  &>:active, &>:visited{
      color: @mainText;
  }
  &:hover{
      color: @mainText;
  }
}