@import "../../../resources/styles/colors";
@import "../../../resources/styles/variables.less";

.box {
    padding: 0.625rem 1rem;
    align-self: stretch;
    font-size:14/16rem;
    color: @primaryText;
}

.information {
    background-color: @infoBackground;
    .box;
    .title {
        color: @infoHeaderText;
        .title;
    }
}

.success {
    background-color: @successCalloutBackground;
    .box;
    .title {
        color: @successHeaderText;
        .title;
    }
}

.warning {
    background-color: @alertCalloutBackground;
    .box;
    .title {
        color: @alertHeaderText;
        .title;
    }
}

.danger {
    background-color: @dangerCalloutBackground;
    .box;
    .title {
        color: @dangerHeaderText;
        .title;
    }
}

.newFeature {
    background: @featureCalloutBackground;
    .box;
    .title {
        color: @featureText;
        .title;
    }
}

.none {
    .box();
}

.title {
    display: flex;
    justify-content: space-between;
    font-weight: @normal;
    + div {
        margin-top: 0.25rem;
    }
    > .note {
        margin-bottom: 0;
    }
}

.icon {
    margin-right: 0.250rem
}
