@import "../../../../../resources/styles/media";

.cardListContainer {
    display: flex;
    flex-direction: column;
    margin: 1rem 0 1.6rem 0;
}


.cardList {
    display: flex;
    flex-direction: row;
    justify-content: left;
    flex-wrap: wrap;
}

.cardList > a {
    display: flex;
    @media (max-width: @screen-sm) {
        flex-direction: column;
    }
}