@import "../../../../../resources/styles/variables.less";
@import "../../../../../resources/styles/colors.less";
@import "../../../../../resources/styles/media";

.card {
    flex: 1;
    min-width: 16.25rem;
    max-width: 18.75rem;
    @media (max-width: @screen-sm) {
        max-width: 100%;
    }
    .description {
        margin-top: 0.5rem;
    }
    &:hover {
        background-color: @cardHover;
    }
}

.onHoverActions{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.onHoverActionsBackdrop {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: @cardHover;
    opacity: 0.5;
}