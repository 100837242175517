@import "../../resources/styles/media";
@import "../../resources/styles/colors";
.transparentFrame {
    display: flex;
    flex-direction: row;

    width: 100%;
    max-width: 1000px;
    max-height: 100%;
    background: transparent;
    border-radius: 4px;
}

.contentPanel {
    display: flex;
    flex-direction: row;
    position: relative;

    width: 100%;
    height: 100%;

    max-width: 600px;
    min-height: 450px;

    background: @paper1;
    border-radius: 4px;
    margin: auto;

    transition: transform 1s;
    transform: translateX(0);

    z-index: 10;
    box-shadow: @shadowLarge;
}

.contentPanelWithHelpPanel {
    @media (min-width: @screen-sm) {
        border-radius: 4px 0 0 4px;
    }
}

.helpPanel {
    flex: auto;
    position: relative;
    overflow: hidden;

    background: @userOnboardingHelpPanelBackground;
    border-radius: 0 4px 4px 0;

    max-width: 400px;
    height: 100%;
    align-content: center;

    @media (max-width: @screen-sm) {
        display: none;
    }
    box-shadow: @shadowLarge;
}

.helpPanel:not(.active):not(.animate) {
    display: none;
}

.helpPanel.animate {
    animation: slide-out 0.35s forwards;
}

.helpPanel.animate.active {
    animation: slide-in 0.35s forwards;
}

@keyframes slide-in {
    0% {
        margin-left: -40%;
        transform: translateX(-60%);
        box-shadow: none;
    }
    100% {
        margin-left: 0;
        transform: translateX(0%);
        width: 100%;
        box-shadow: @shadowLarge;
    }
}

@keyframes slide-out {
    0% {
        margin-left: 0;
        transform: translateX(0%);
        box-shadow: @shadowLarge;
    }
    100% {
        margin-left: -40%;
        transform: translateX(-60%);
        box-shadow: none;
    }
}

.helpPanelBtn {
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 10;
    @media (max-width: @screen-sm) {
        visibility: hidden;
    }
}

.helpPanelText {
    display: block;
    width: 80%;
    margin: 30px auto 0;
    color: white;
    line-height: 24px;
    font-weight: 400;
    font-size: 16px;
}

.helpPanelImage {
    display: block;
    width: 80%;
    margin: 80px auto 20px;
}

.padded {
    padding: 5px 10px 10px;
}