@import "../../../../../resources/styles/media";

.main {
    width: 100%;
    max-width: 1280px;
    margin: 2rem auto;
}

.callouts {
    margin-bottom: 20px;
}

.outerContainer {
    padding: 1rem;
    display: flex;
    flex-direction: row;
    @media (max-width: @screen-md) {
        margin: auto;
        padding: 0;
        flex-direction: column-reverse;
    }
}

.contentContainer {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    @media (max-width: @screen-md) {
        margin: auto;
        width: 100%;
    }
}

.blankPageImage {
    display: flex;
    justify-content: center; 
    width: 50%;
    @media (max-width: @screen-md) {
        width: 100%;
        max-width: 100%;
        margin: auto;
        padding: 0 1rem;
    }
}

.content {
    max-width: 430px;
    @media (max-width: @screen-md) {
        padding: 0 1rem;
    }
}

.paragraph {
    line-height: 24px;
}

.buttons {
    display: flex;
    margin-top: 26px;
    flex-wrap: wrap;
}

.flexCenter {
    display: flex;
    justify-content: center;
    align-items: center;
}

.navButtonWrap {
    margin-left: 24px;
}

.image {
    width: 100%;
    max-width: 100%;
    height: auto;
    @media (max-width: @screen-md) {
        max-width: 400px;
        margin-bottom: 1rem;
    }
}